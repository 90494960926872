import React, { useState } from 'react'

import { Wrapper } from 'src/ui/layout'
import { HeroDesktopSecondary } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'
import { styled } from 'src/ui/theme'

import { TVideoReason } from './types'

type Props = TVideoReason

const VideoBlock = styled.div`
  position: relative;
`

const VideoBlockTitle = styled.div<{ top: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
  right: 0;
  max-width: 730px;
  width: 100%;
  padding: 64px 40px;
  background: ${({ theme }) => theme.colors.white.primary};
  transition: top 0.3s;
`

export const VideoReason = ({
  videoId,
  videoType,
  title,
  description,
}: Props) => {
  const [isVideoActive, setIsVideoActive] = useState(false)
  return (
    <Wrapper>
      <VideoBlock>
        <HeroDesktopSecondary
          adornment={false}
          height={724}
          videoId={videoId}
          videoType={videoType}
          onPause={() => setIsVideoActive(false)}
          onPlay={() => setIsVideoActive(true)}
        />
        <VideoBlockTitle top={isVideoActive ? -330 : 0}>
          <Header size="h2" as="h2">
            {title}
          </Header>
          <Body>{description}</Body>
        </VideoBlockTitle>
      </VideoBlock>
    </Wrapper>
  )
}
