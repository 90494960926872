import React, { useMemo } from 'react'
import { graphql } from 'gatsby'

import { Query } from 'hr/graphql-types'
import { useDimensions } from 'src/ui/dimensions'
import { HBox } from 'src/ui/layout'
import { RootLayout } from 'src/layouts'
import { Hero, VideoBlock, ImageBlock } from 'src/features/loyalty'
import { Meta } from 'src/features/seo'
import { styled } from 'src/ui/theme'

const Content = styled.div<{ isMobile: boolean }>`
  display: grid;
  gap: ${({ isMobile }) => (isMobile ? 120 : 180)}px;
  margin-top: ${({ isMobile }) => (isMobile ? 80 : 120)}px;
  margin-bottom: ${({ isMobile }) => (isMobile ? 80 : 74)}px;
`

type Props = {} & { data: Query }

const Benefits = ({ data }: Props) => {
  const { isMobile } = useDimensions()

  const images = useMemo(
    () =>
      data.allFile.edges.reduce<TImages>((acc, img) => {
        return {
          ...acc,
          [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
        }
      }, {}),
    [data.allFile.edges]
  )

  return (
    <RootLayout>
      <Meta
        title="Работа в IT с удовольствием — KODE"
        description="Ищете работу в IT? Компания KODE приглашает тебя стать частью дружной команды: путешествия с коллегами, спорт и развлечения ✔ Оставляйте заявк и получайте оффер!"
      />
      {isMobile ? <HBox /> : null}
      <Hero images={images} />

      <Content isMobile={isMobile}>
        <ImageBlock
          image={images['dms']}
          title={texts.dms.title}
          description={texts.dms.description}
        />
        <ImageBlock
          imagePosition="right"
          image={images['sport']}
          title={texts.sport.title}
          description={texts.sport.description}
        />
        <VideoBlock
          videoId="47e5e0f42e90b06198c46df0cf66805f"
          videoType="rutube"
          title={texts.trips.title}
          description={texts.trips.description}
        />
        <ImageBlock
          imagePosition="right"
          image={images['moving']}
          title={texts.moving.title}
          description={texts.moving.description}
        />
        <ImageBlock
          image={images['education']}
          title={texts.education.title}
          description={texts.education.description}
        />
        <ImageBlock
          imagePosition="right"
          image={images['breakfast']}
          title={texts.breakfast.title}
          description={texts.breakfast.description}
        />
        <VideoBlock
          videoId="acef59c5300590d51d1c1ae5b059693a"
          videoType="rutube"
          title={texts.deadline.title}
          description={texts.deadline.description}
        />
        <ImageBlock
          imagePosition="right"
          image={images['corporats']}
          title={texts.corporats.title}
          description={texts.corporats.description}
        />
        <ImageBlock
          image={images['teambuilding']}
          title={texts.teambuilding.title}
          description={texts.teambuilding.description}
        />
        <ImageBlock
          imagePosition="right"
          image={images['clock']}
          title={texts.clock.title}
          description={texts.clock.description}
        />
        <VideoBlock
          videoId="b09402cf522a5cb10d7506ed7efab96e"
          videoType="rutube"
          title={texts.merch.title}
          description={texts.merch.description}
        />
      </Content>
    </RootLayout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/loyalty/i" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 1900) {
              srcSet
              base64
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`

export default Benefits

const texts = {
  dms: {
    title: 'ДМС',
    description:
      'Подключаем всех сотрудников с первого дня работы. Стоматология, консультации специалистов, исследования, телемедицина, даже консультация ветеринара — все включено.',
  },
  sport: {
    title: 'Спорт',
    description:
      'Компенсируем 100% трат на групповые занятия спортом с коллегами. Кодеры занимаются скалолазанием, йогой, футболом, велоспортом – выбирайте, что больше нравится.',
  },
  trips: {
    title: 'Путешествия',
    description:
      'Мы уверены, что самый лучший тимбилдинг – это путешествие. Поэтому раз в год отправляемся со всеми кодерами в путешествие. Мы уже гуляли по рынку Марокко, любовались горами Дагестана и фьордами Мурманска, знакомились с традиционной кухней в Италии и видели Испанскую корриду. А еще мы любим походы и с удовольствием исследуем окрестности Калининграда.',
  },
  moving: {
    title: 'Помощь с переездом',
    description:
      'Если вы давно хотели перебраться в Питер или Калининград, мы возместим ваши траты на релокацию. Компания берет на себя оплату билетов, отправку вещей транспортной компанией и трансферы. Так же мы позаботимся о поиске жилья для вас.',
  },
  education: {
    title: 'Обучение',
    description:
      'KODE – международная компания, поэтому кодерам важно знать английский. Мы организуем групповое обучение и компенсируем 50% от стоимости занятий. А еще компания оплачивает профессиональные курсы, участие в конференциях, устраивает свои обучающие ивенты и постоянно пополняет корпоративную библиотеку.',
  },
  breakfast: {
    title: 'Завтраки отделов',
    description:
      'Мы верим, что лучшее утро — то, что начинается с вкусного завтрака в приятной компании. Поэтому раз в неделю каждый отдел может сходить на завтрак, а компания компенсирует затраты на него. Можно пригласить гостей из других отделов, чтобы обсудить новый проект или идею.',
  },
  deadline: {
    title: 'Ласковый дедлайн',
    description:
      'У нас есть собственный кавер-бэнд, который выступает на каждом корпоративе. Каждый желающий может стать частью рок-коллектива и почувствовать себя звездой. Вам стоит работать в KODE хотя бы затем, чтобы услышать этих ребят.',
  },
  corporats: {
    title: 'Корпоративы',
    description:
      'Кодеры со всей страны съезжаются в Калининград дважды в год: в августе на день рождения компании, в декабре — на Новый год. Наши корпоративы всегда масштабные, тематические и очень яркие. Место проведения каждый раз разное, но все они невероятно атмосферные и уютные.',
  },
  teambuilding: {
    title: 'Тимбилдинг',
    description:
      'Картинг, спа, яхты, рестораны, мастер-классы — все это помогает кодерам отдыхать и лучше узнавать команду. Стараемся собираться с коллегами раз в полгода, даже если живем в разных города.',
  },
  clock: {
    title: 'Гибкие часы',
    description: 'Начинаем рабочий день, когда удобно. И нам это нравится!',
  },
  merch: {
    title: 'Наш мерч',
    description:
      'Вместо слов лучше посмотрите на последнюю коллекцию нашего мерча. Мы регулярно выпускаем новые дропы и следим за разнообразием.',
  },
}
